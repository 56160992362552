const liveSkus = [
  {
    sku: "sku_H9gYeuvP4LPDgK",
    id: "buggy_race_day",
    price: "5",
    title: "Buggy Race",
    subtitle: "Beach Run",
    players: "2 - 30 players",
    length: "24 hours",
    plays: "unlimited",
    description: "Take control of a dune buggy in this frenetic desert drag race. Last buggy standing wins."
  },
  {
    sku: "sku_H9h59bDnI0WdiW",
    id: "buggy_race_night",
    price: "5",
    title: "Buggy Race",
    subtitle: "After Dark",
    players: "2 - 30 players",
    length: "24 hours",
    plays: "unlimited",
    description: "Take control of a dune buggy in this frenetic desert drag race. Last buggy standing wins."
  }
];

const testSkus = [
  {
    sku: "sku_H75y0q2SEYSc3T",
    id: "buggy_race_day",
    price: "5",
    title: "Buggy Race",
    subtitle: "Beach Run",
    players: "2 - 30 players",
    length: "24 hours",
    plays: "unlimited",
    description: "Take control of a dune buggy in this frenetic desert drag race. Last buggy standing wins.",
  },
  {    
    sku: "sku_H8x7XlH5E9czgU",
    id: "buggy_race_night",
    price: "5",
    title: "Buggy Race",
    subtitle: "After Dark",
    players: "2 - 30 players",
    length: "24 hours",
    plays: "unlimited",
    description: "Take control of a dune buggy in this frenetic desert drag race. Last buggy standing wins."
  }
];

const skus = process.env.GATSBY_APP_ENV === 'production' ? liveSkus : testSkus;

export const getGameDataBySKU = (sku) => {
  return skus.find(obj => obj.sku === sku);
}

export const getGameDataById = (id) => {
  return skus.find(obj => obj.id === id);
}